import React, {FormEvent, memo, useState} from 'react';
import IconFigLeafAuraCurrentColor from '@common/images/icon-fl-aura-logo-current-color.svg';
import IconLowRisk from '@common/images/icon-low-risk.svg';
import IconUnknownRisk from '@common/images/icon-question-mark.svg';
import IconExclamationCurrentColor from '@common/images/icon-exclamation-current-color.svg';
import {ButtonComponent} from '@js/pure-components/button/button.component';
import {RiskRankComponent} from '@js/pure-components/risk-rank/risk-rank.component';
import {WhiteInputComponent} from '@js/pure-components/white-input/white-input.component';
import {categoriesConfig} from '@js/landing/components/category/config';
import {Spinner} from '@js/pure-components/spinner/spinner.component';
import {RiskTypes} from '@js/landing/redux/constants';
import {emailRegex} from '@js/utils/helpers';

import {HeaderProps} from './header.types';
import './_header.scss';

export const Header = memo<HeaderProps>(
  ({
    isLoading,
    isDataFetched,
    availableCategoryTypes,
    riskCategories,
    email,
    getRiskCategories,
    exposedItemsCount,
    globalRiskType,
  }) => {
    const [inputValue, setInputValue] = useState<string>('');
    const [isInputValueValid, setIsInputValueValid] = useState(true);

    const handleSubmitEmail = (event: FormEvent, location: 'top' | 'bottom') => {
      event.preventDefault();
      if (!emailRegex.test(inputValue)) {
        setIsInputValueValid(false);
      } else {
        setIsInputValueValid(true);
        getRiskCategories({email: inputValue, source: location === 'top' ? 'headerTopCta' : 'headerBottomCta'});
        setInputValue('');
      }
    };

    let riskDescription;
    let titleText = '';

    if (!isDataFetched && !isLoading) {
      riskDescription = (
        <>
          Your privacy is important. Acer advises you to perform a FREE* privacy checkup, powered by FigLeaf.
          <br />
          Discover the data associated with your email address with a personalized Exposed Data Report across
          <br />
          these categories:
        </>
      );
      titleText = 'Your information could be exposed';
    } else if (isLoading) {
      riskDescription = (
        <>
          We are searching for private data points associated with ${email}.
          <br />
          Here are major data abuse associated risks that we are checking for:
        </>
      );
      titleText = 'Scanning...';
    } else {
      riskDescription = (
        <>
          We found <strong>{exposedItemsCount || 0} exposed private data points</strong> associated with {email}
          .<br />
          Here are major data abuse associated risks that we checked:
        </>
      );
      titleText = `Your Personal Data Exposure is ${
        RiskTypes[globalRiskType].charAt(0).toUpperCase() + RiskTypes[globalRiskType].slice(1)
      }`;
    }

    let ctaComponent = null;

    if (!isLoading && !isDataFetched) {
      ctaComponent = (
        <div className="header__cta-wrapper">
          <form
            onSubmit={(event: FormEvent) => {
              handleSubmitEmail(event, 'top');
            }}
            className="header__cta"
          >
            <WhiteInputComponent
              value={inputValue}
              onChange={setInputValue}
              placeholder="Your email address..."
              isValid={isInputValueValid}
              errorMessage="Please, enter a valid email address"
            />
            <ButtonComponent type="submit" text="Check My Exposure" mode="green" />
          </form>
          <span className="header__cta-description">
            FigLeaf collects your email address to provide the Exposed Data Risk Report. By entering your email address
            <br />
            and clicking &apos;Check My Privacy&apos;, you agree to the Aura FigLeaf{' '}
            <a
              className="header__cta-link"
              rel="noreferrer"
              target="_blank"
              href="https://figleaf.com/terms-of-service/"
            >
              Terms of Service
            </a>{' '}
            and{' '}
            <a
              className="header__cta-link"
              rel="noreferrer"
              target="_blank"
              href="https://aura.com/legal/privacy-policy"
            >
              Privacy Policy
            </a>
            .
          </span>
        </div>
      );
    }

    const amountOfExposedCategories = availableCategoryTypes.filter(
      category => riskCategories[category].riskType !== RiskTypes.low,
    ).length;

    return (
      <div className={`header header--${RiskTypes[globalRiskType]}`}>
        <div className="header__content">
          <div className="header__top">
            <IconFigLeafAuraCurrentColor className="header__logo" />
          </div>
          <div className="header__bottom">
            <div className="header__details">
              <div className="header__title">{titleText}</div>
              <div className="header__subtitle">{riskDescription}</div>

              <div className="header__badges">
                {availableCategoryTypes.map((item, index) => {
                  let iconComponent = null;
                  if (isLoading) {
                    iconComponent = <Spinner sizeModifier="bg" />;
                  } else if (riskCategories[item].riskType === RiskTypes.low) {
                    iconComponent = <IconLowRisk />;
                  } else if (riskCategories[item].riskType === RiskTypes.moderate) {
                    iconComponent = <IconExclamationCurrentColor className="header__badge--moderate" />;
                  } else if (riskCategories[item].riskType === RiskTypes.high) {
                    iconComponent = <IconExclamationCurrentColor className="header__badge--high" />;
                  } else {
                    iconComponent = <IconUnknownRisk />;
                  }

                  return (
                    <a className="header__badge" href={`#${item}`} key={index}>
                      <span className="header__badge-text">
                        <div className="header__badge-icon">{iconComponent}</div>
                        {categoriesConfig[item].title}
                      </span>
                    </a>
                  );
                })}
              </div>
              {ctaComponent}
            </div>
            <div className="header__rank">
              <RiskRankComponent
                amountOfExposedCategories={amountOfExposedCategories}
                totalAmount={availableCategoryTypes.length}
                isDataFetched={isDataFetched}
                riskType={globalRiskType}
              />
            </div>
          </div>
        </div>
      </div>
    );
  },
);

Header.displayName = 'Header';

import {call, put, select, takeEvery} from 'redux-saga/effects';
import {getRiskCategories} from '@js/api';
import {setPageData, setRiskCategories} from './actions';
import {GET_RISK_CATEGORIES, RiskTypes} from './constants';
import {
  TGetRiskCategoriesAction,
  TParsedDataKeys,
  TRiskCategoriesState,
  TRiskCategoryType,
  TSocialProfiles,
} from './types';
import {RiskCategoriesResponse} from '@js/api/types';
import {sendAnalytics} from '@js/analytics/sendAnalytics';
import {selectAvailableCategoryTypes, selectIsDataFetched} from './selectors';
import {riskCategoriesInitialState} from './reducers/risk-categories.reducer';
import {getParsedData} from '@js/api/parsers';

export function* getRiskCategoriesSaga(action: TGetRiskCategoriesAction) {
  const isDataFetched = yield select(selectIsDataFetched);

  if (isDataFetched) {
    // reset state to default if data was fetched before
    yield put(setRiskCategories(riskCategoriesInitialState));
  }

  yield put(
    setPageData({
      isLoading: true,
      isDataFetched: false,
      email: action.payload.email,
      globalRiskType: isDataFetched ? RiskTypes.unknown : undefined,
    }),
  );

  sendAnalytics({
    eventName: 'scan_start',
    payload: {source: action.payload.source},
  });
  try {
    const response: RiskCategoriesResponse = yield call(getRiskCategories, action.payload.email);

    sendAnalytics({
      eventName: 'scan_finish',
      payload: {full_result: Boolean(response.person)},
    });
    const parsedData = getParsedData(response);

    const parsedDataKeys = Object.keys(parsedData) as Array<TParsedDataKeys>;
    let exposedItemsCount = 0;
    parsedDataKeys.forEach(key => (exposedItemsCount += parsedData[key].length));

    let discoveredRecordsRiskType = RiskTypes.unknown;
    if (
      parsedData.phones.length ||
      parsedData.ids.length ||
      parsedData.idsDriversLicence.length ||
      parsedData.idsPassport.length ||
      parsedData.idsSsn.length ||
      parsedData.idsTaxId.length
    ) {
      discoveredRecordsRiskType = RiskTypes.high;
    } else if (
      parsedData.socialReddit.length ||
      parsedData.socialFacebook.length ||
      parsedData.socialSkype.length ||
      parsedData.socialYahoo.length ||
      parsedData.socialYoutube.length ||
      parsedData.socialInstagram.length ||
      parsedData.socialPinterest.length ||
      parsedData.socialStackoverflow.length
    ) {
      discoveredRecordsRiskType = RiskTypes.moderate;
    } else {
      discoveredRecordsRiskType = RiskTypes.low;
    }

    let breachedAccountsRiskType = RiskTypes.unknown;
    if (parsedData.passwords.length || parsedData.securityQuestions.length) {
      breachedAccountsRiskType = RiskTypes.high;
    } else if (parsedData.logins.length) {
      breachedAccountsRiskType = RiskTypes.moderate;
    } else {
      breachedAccountsRiskType = RiskTypes.low;
    }

    let exposedLocationsRiskType = RiskTypes.unknown;
    if (parsedData.locations.length) {
      exposedLocationsRiskType = RiskTypes.high;
    } else if (parsedData.ipAddresses.length) {
      exposedLocationsRiskType = RiskTypes.moderate;
    } else {
      exposedLocationsRiskType = RiskTypes.low;
    }

    const socialProfilesData: TSocialProfiles = {
      facebook: [...parsedData.socialFacebook],
      google: [...parsedData.socialGoogle],
      youtube: [...parsedData.socialYoutube],
      instagram: [...parsedData.socialInstagram],
      yahoo: [...parsedData.socialYahoo],
      skype: [...parsedData.socialSkype],
      reddit: [...parsedData.socialReddit],
      stackoverflow: [...parsedData.socialStackoverflow],
      pinterest: [...parsedData.socialPinterest],
    };

    const generalInfoData: Array<{value: string; hidden?: boolean}> = [];

    parsedData.names.length && generalInfoData.push(parsedData.names[0]);
    parsedData.gender.length && generalInfoData.push(parsedData.gender[0]);
    parsedData.language.length && generalInfoData.push(parsedData.language[0]);

    const newRiskCategories: TRiskCategoriesState = {
      discoveredRecords: {
        riskType: discoveredRecordsRiskType,
        items: {
          generalInfo: {
            name: 'General Info',
            riskBadge: 'Publicy Available',
            data: generalInfoData,
          },
          ids: {
            name: 'IDs',
            riskBadge: 'Exposed',
            subItems: {
              idsSsn: {
                name: 'SSN',
                data: parsedData.idsSsn,
              },
              idsDriversLicence: {
                name: 'Driver’s license',
                data: parsedData.idsDriversLicence,
              },
              idsPassport: {
                name: 'Passport',
                data: parsedData.idsPassport,
              },
              idsTaxId: {
                name: 'Tax ID',
                data: parsedData.idsTaxId,
              },
            },
          },
          phones: {
            name: 'Phones',
            riskBadge: 'Exposed',
            data: parsedData.phones,
          },
          socialProfiles: {
            name: 'Social profiles',
            riskBadge: 'Publicy Available',
            socialProfilesData,
          },
        },
      },
      breachedAccounts: {
        riskType: breachedAccountsRiskType,
        items: {
          accounts: {
            name: 'Accounts',
            riskBadge: 'Exposed',
            data: parsedData.accounts,
          },
          logins: {
            name: 'Logins',
            riskBadge: 'Exposed',
            data: parsedData.logins,
          },
          passwords: {
            name: 'Passwords',
            riskBadge: 'Exposed',
            data: parsedData.passwords,
          },
          securityQuestions: {
            name: 'Security questions',
            data: parsedData.securityQuestions,
            riskBadge: 'Exposed',
          },
        },
      },
      exposedLocations: {
        riskType: exposedLocationsRiskType,
        items: {
          locations: {
            name: 'Locations',
            riskBadge: 'Exposed',
            data: parsedData.locations,
          },
          ipAddresses: {
            name: 'IP addresses',
            riskBadge: 'Exposed',
            data: parsedData.ipAddresses,
          },
        },
      },
    };

    const availableCategoryTypes: Array<TRiskCategoryType> = yield select(selectAvailableCategoryTypes);

    const riskMap = {low: 0, moderate: 0, high: 0};
    availableCategoryTypes.forEach(item => {
      if (newRiskCategories[item].riskType === RiskTypes.low) {
        riskMap['low']++;
      }
      if (newRiskCategories[item].riskType === RiskTypes.moderate) {
        riskMap['moderate']++;
      }
      if (newRiskCategories[item].riskType === RiskTypes.high) {
        riskMap['high']++;
      }
    });

    let globalRiskType = RiskTypes.unknown;
    if (riskMap.high) {
      globalRiskType = RiskTypes.high;
    } else if (riskMap.moderate) {
      globalRiskType = RiskTypes.moderate;
    } else {
      globalRiskType = RiskTypes.low;
    }

    sendAnalytics({
      eventName: 'user_status_defined',
      payload: {
        global: globalRiskType,
        discoveredRecords: discoveredRecordsRiskType,
        breachedAccounts: breachedAccountsRiskType,
        exposedLocations: exposedLocationsRiskType,
      },
    });

    yield put(setRiskCategories(newRiskCategories));
    yield put(setPageData({isLoading: false, isDataFetched: true, exposedItemsCount, globalRiskType}));
  } catch (e) {
    console.error(e);
    yield put(setPageData({isLoading: false, isDataFetched: false, isShowErrorPopup: true, email: undefined}));
    sendAnalytics({
      eventName: 'scan_was_failed',
      payload: {error_message: JSON.stringify(e)},
    });
  }
}

export function* pageWatcher() {
  yield takeEvery(GET_RISK_CATEGORIES, getRiskCategoriesSaga);
}

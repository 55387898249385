import React from 'react';
import {CategoryItemComponent} from '@js/landing/components/category-item/category-item.component';
import {CategoryPersonItemComponent} from '@js/landing/components/category-person-item/category-person-item.component';
import {CategoryItemWithSubitemsComponent} from '@js/landing/components/category-item-with-subitems/category-item-with-subitems.component';
import {ButtonComponent} from '@js/pure-components/button/button.component';
import {sendAnalytics} from '@js/analytics/sendAnalytics';
import {TPersonRiskCategoryItem, TRiskCategoryItem} from '@js/landing/redux/types';
import {RiskTypes} from '@js/landing/redux/constants';
import {categoriesConfig} from './config';
import {FigleafGradientLogoComponent} from '@js/pure-components/fl-gradient-logo/fl-gradient-logo.component';

import {CategoryProps} from './category.types';
import './_category';

export const CategoryComponent: React.FC<CategoryProps> = props => {
  const category = props.riskCategories[props.categoryName];
  if (!category) {
    return null;
  }
  const {items, riskType} = category;

  const categoryItems = Object.entries(items).map(([key, value]) => {
    const item = value as TRiskCategoryItem;
    if (key === 'person') {
      const personData = value as TPersonRiskCategoryItem;
      return <CategoryPersonItemComponent isDataFetched={props.isDataFetched} key={key} personData={personData} />;
    }
    if (item.subItems) {
      return (
        <CategoryItemWithSubitemsComponent
          key={key}
          itemKey={key}
          isDataFetched={props.isDataFetched}
          itemName={item.name}
          riskBadge={item.riskBadge}
          subItems={item.subItems}
        />
      );
    }

    const data = item.data as Array<{value: string; hidden?: boolean}>;
    return (
      <CategoryItemComponent
        key={key}
        itemKey={key}
        isDataFetched={props.isDataFetched}
        itemName={item.name}
        riskBadge={item.riskBadge}
        data={data}
        socialProfilesData={item.socialProfilesData}
        riskType={category.riskType}
      />
    );
  });

  const categoryClassname = ['category'];
  const categoryRiskTypeClassname = ['category__risk-type'];
  let riskTypeText = 'Unknown';
  if (riskType === RiskTypes.high) {
    categoryClassname.push('category--high-risk');
    categoryRiskTypeClassname.push('category__risk-type--high');
    riskTypeText = 'High Risk';
  }
  if (riskType === RiskTypes.moderate) {
    categoryClassname.push('category--moderate-risk');
    categoryRiskTypeClassname.push('category__risk-type--moderate');
    riskTypeText = 'Moderate Risk';
  }
  if (riskType === RiskTypes.low) {
    categoryClassname.push('category--low-risk');
    categoryRiskTypeClassname.push('category__risk-type--low');
    riskTypeText = 'Low Risk';
  }

  const unknownStateButtonText = 'Check My Privacy';
  let buttonText = unknownStateButtonText;
  let descriptionText: string = categoriesConfig[props.categoryName].description;

  if (props.isDataFetched) {
    switch (props.globalRiskType) {
      case RiskTypes.low: {
        buttonText = categoriesConfig[props.categoryName].buttonKeepText;
        descriptionText = categoriesConfig[props.categoryName].descriptionLow;
        break;
      }
      case RiskTypes.moderate: {
        buttonText = categoriesConfig[props.categoryName].buttonProtectText;
        descriptionText = categoriesConfig[props.categoryName].descriptionModerate;
        break;
      }
      case RiskTypes.high: {
        buttonText = categoriesConfig[props.categoryName].buttonProtectText;
        descriptionText = categoriesConfig[props.categoryName].descriptionHigh;
        break;
      }
      default: {
        descriptionText = categoriesConfig[props.categoryName].descriptionLow;
        break;
      }
    }
  }

  return (
    <div className={categoryClassname.join(' ')} id={props.categoryName}>
      <div className="category__inner">
        <div className="category__header">
          <div className="category__header-col">
            <span className="category__part-number">{`Part ${props.partNumber} of ${props.partsCount}`}</span>
            <h3 className="category__title">{categoriesConfig[props.categoryName].title}</h3>
            <span className={categoryRiskTypeClassname.join(' ')}>{riskTypeText}</span>
          </div>
          <div className="category__header-col">
            <div className="category__description">
              <span>{descriptionText}</span>
            </div>
          </div>
        </div>
        <div className="category__content">{categoryItems}</div>
        {!props.isDataFetched && (
          <>
            <h4 className="category__button-description">
              {categoriesConfig[props.categoryName].buttonProtectDescription}
            </h4>
            <div className="category__button">
              <ButtonComponent
                onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}
                text={buttonText}
                sizeModifier="sm"
                mode="green"
                disabled={props.isLoading}
                fullWidth
              />
            </div>
          </>
        )}
      </div>
      {props.isDataFetched && (
        <div className="category__buynow-section">
          <div className="category__buynow-logo">
            <FigleafGradientLogoComponent />
          </div>
          <h3 className="category__buynow-description">{categoriesConfig[props.categoryName].buttonDescription}</h3>
          <ButtonComponent
            onClick={() => {
              sendAnalytics({
                eventName: 'click_on_buy_now_button',
                payload: {
                  location: props.categoryName,
                },
              });
              window.location.href = props.buyNowUrl;
            }}
            text="Activate with 67% Discount"
            sizeModifier="sm"
            mode="green"
            disabled={props.isLoading}
            fullWidth
          />
        </div>
      )}
    </div>
  );
};
